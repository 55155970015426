@import '../../sass/variables.scss';

.Showcase {
    height: 86vh;
    max-width: 1200px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    padding: 8rem 2rem 0rem 2rem;

    @include tablet {
        max-width: 100%;
    }

    .Showcase__left {
        width: 50%;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        position: relative;

        @include tablet {
            width: 100%;
        }

        h1 {
            font-family: $font2;
            font-size: 3.5rem;
            font-weight: 600;
            line-height: 4rem;
            margin-top: 2rem;
            z-index: 1;
        }

        small {
            font-family: $font3;
            color: rgb(96, 96, 96);
            font-size: 1rem
        }

        span.bgCircle {
            @extend .defaultBgCircle;
            width: 140px;
            height: 140px;
            right: 100px;
        };

        #hireme {
            cursor: pointer;
            text-decoration: none;
            width: fit-content;
            padding: .7rem 1.2rem;
            font-family: $font3;
            font-size: 1rem;
            color: white;
            background-color: $blue;
            border: none;
            font-weight: 200;
            border-radius: 22px;
            margin-top: 1rem;
            transition: ease-in-out 200ms;

            &:hover {
                box-shadow: -1px 1px 20px -1px rgba(0, 0, 0, 17%);
            }
        }
    }

    .Showcase__right {
        width: 50%;
        display: flex;
        justify-content: center;
        position: relative;

        @include tablet {
            width: 0%;
        }
    }

    .Showcase__bottom {
        margin-top: 3rem;
        display: flex;
        gap: 2rem;
        align-items: flex-end;
    }
}