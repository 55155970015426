@import '../../sass/variables.scss';

.Footer {
    background-color: $black;
    border-top: 6px solid white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    color: $white;
    gap: 5rem;

    h1 {
        font-family: $font1;
        text-align: center;
    }

    hr {
        width: 100%;
        border: none;
        height: 1px;
        color: #333;
        background-color: #333;
    }

    > img {
        width: 200px;
    }

    > div:first-of-type {
        display: flex;
        flex-direction: column;
        align-items: center;


        .contact {
            text-decoration: none;
            color: white;
            font-size: 1.3rem;
            font-family: $font1;
            
            @include tablet {
                font-size: 1rem;
            }

            &:hover {
                text-decoration: underline;
            }

            i {
                margin-right: 1rem;
            }
        }
    }

    div {
        display: flex;
        gap: 2rem;

        
        img {
            width: 30px;
            cursor: pointer;
            transition: all 200ms;

            &:hover {
            transform: scale(1.1);
            }
        }
    }
}