@import './sass/variables.scss';

* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

.App {
  display: flex;
  flex-direction: column;
}

.Arrow {
  animation: slideArrow 1s ease-in-out infinite;
  position: fixed;
  bottom: 3rem;
  left: 50%;
  transform: translate(-50%, 0);
  font-size: x-large;
}

@keyframes slideArrow {
  0%,
  100% {
    transform: translate(0, 0) rotate(0deg);
  }

  50% {
    transform: translate(0px, 20px) rotate(0deg);
  }
}