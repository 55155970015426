@import '../../sass/variables.scss';

.Header {
    position: fixed;
    top: 0px;
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: white;
    z-index: 3;
    transition: all 400ms;

    &.dark {
        background-color: $black;

        ul {
            color: white;
        }
    }
    
    ul {
        max-width: 1200px;
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        font-family: $font3;

        @include tablet {
            max-width: 100vw;

            a:first-of-type {
                margin: auto;
            }

            a:not(:first-of-type) {
                display: none;
            }
        }
        
        a {
            text-decoration: none;
            padding: 10px 15px;
            color: inherit;
            border-radius: 20px;
            list-style: none;
            transition: 200ms;
            cursor: pointer;

            img {
                width: 125px;
            }

            &:hover:not(:first-child) {
                background: linear-gradient(to left, $orange, $yellow);
                color: white;
            }
        }
    }
}