@import '../../sass/variables.scss';

.Skills {
    min-width: 1000px;
    max-width: 1200px;
    margin: auto;
    padding: 12rem 0rem;
    height: 100vh;
    display: flex;
    margin-top: 50vh;

    @include tablet {
        max-width: 100%;
        min-width: auto;
        padding: 2rem;
    }

    .Skills__left {
        flex: 1;
        position: relative;

        @include tablet {
            width: 100%;
        }

        span.bgCircle {
            @extend .defaultBgCircle;
            position: absolute;
            z-index: 1;
            top: -25px;
            right: 50px !important;
            width: 140px;
            height: 140px;
            right: 100px;
        };
        
        h1 {
            font-family: $font2;
            font-size: 3.5rem;
            font-weight: 600;
            line-height: 4rem;
            max-width: max-content;
            z-index: 2;
            position: relative;
        }

        div {
            max-width: 500px;
            display: flex;
            flex-wrap: wrap;
            gap: 25px;
            margin-top: 2rem;

            img {
                transition: all 300ms ease;

                &:hover {
                    transform: scale(1.1);
                }
            }
        }
    }

    .Skills__right {
        flex: 1;

        @include tablet {
            flex: 0;
        }
    }
}