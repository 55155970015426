@import '../../sass/variables.scss';

.Profile {
    position: fixed;
    width: 40%;
    right: 100px;
    top: 20vh;
    z-index: 1;

    @include tablet {
        display: none;
    }

    &.hidden {
        opacity: 0;
        transition: ease-in-out 500ms;
        transform: translate(10%, 0);
        z-index: 0;
    }

    &:hover {
        .triangle2 {
            transition: all 200ms !important;
            top: 11px !important;
        }
    }

    .Profile__inner {
        display: flex;
        justify-content: center;

        img {
            position: absolute;
            z-index: 2;
        }

        .triangle1 {
            position: relative;
            width: 0;
            height: 0;
            border: 200px solid transparent;
            border-top: 0;
            border-bottom: 360px solid black;
            z-index: 1;

            .triangle1__inner { 
                position: absolute;
                width: 0;
                height: 0;
                border: 194px solid transparent;
                border-top: 0;
                border-bottom: 352px solid $yellow;
                left: -194px;
                top: 5px;
            }
        }

        .triangle2 {
            position: absolute;
            top: -10px;
            left: 105px;
            width: 0;
            height: 0;
            border: 200px solid transparent;
            border-top: 0;
            border-bottom: 360px dashed black;
        }
    }
}