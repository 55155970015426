$blue: #6de2ff;
$black: black;
$white: whitesmoke;
$grey0: #101010;
$darkbg: #0a0a0a;
$yellow: #FFBD59;
$orange: #FF914D;
$blue: #5271FF;
$boxshadow: -1px 1px 20px -1px rgba(0, 0, 0, 0.75);

/* FONTS */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Questrial&display=swap');
$font1: 'Poppins', sans-serif;
$font2: 'League Spartan', sans-serif;
$font3: 'Questrial', sans-serif;

/* MEDIA QUERIES */
$small-mobile-width: 320px;
$mobile-width: 425px;
$large-mobile-width: 620px;
$tablet-width: 768px;
$large-tablet-width: 1024px;
$desktop-width: 1280px;
$large-desktop-width: 1440px;

@mixin small-mobile {
    @media (max-width: #{$small-mobile-width}) {
        @content;
    }
}
@mixin mobile {
    @media (max-width: #{$mobile-width - 1px}) {
        @content;
    }
}
@mixin large-mobile {
    @media (max-width: #{$large-mobile-width - 1px}) {
        @content;
    }
}
@mixin tablet {
    @media (max-width: #{$tablet-width - 1px}) {
        @content;
    }
}

@mixin large-tablet {
    @media (max-width: #{$large-tablet-width - 1px}) {
        @content;
    }
}
@mixin desktop {
    @media (max-width: #{$desktop-width}) {
        @content;
    }
}
@mixin large-desktop {
    @media (max-width: #{$large-desktop-width}) {
        @content;
    }
}

.defaultBgCircle {
    border-radius: 50%;
    background: -webkit-linear-gradient(to left, $orange, $yellow); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to left, $orange, $yellow); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    position: absolute;
    transform: rotateZ(307deg);
}